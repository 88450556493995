import { ParsedUrlQuery } from "querystring";

import domtoimage from "dom-to-image";
import { createContext, PropsWithChildren, useContext, useRef, useState } from "react";
import { createPortal, flushSync } from "react-dom";

type RenderContentOptions = {
  jsx?: JSX.Element;
  elementRef?: React.RefObject<HTMLElement>;
  width?: string;
  height?: string;
  query?: Record<string, string>;
};

export type ScreenshotContextType = {
  renderContentForScreenshots: (options: RenderContentOptions) => void;
  takeScreenshots: (querySelectors: string[]) => Promise<{ [key: string]: string }>;
  query: ParsedUrlQuery;
};

const ScreenshotContext = createContext<ScreenshotContextType | undefined>(undefined);

const useScreenshotContext = () => {
  const context = useContext(ScreenshotContext);

  if (!context) {
    return {};
  }

  return context;
};

const ScreenshotContextProvider = ({ children }: PropsWithChildren) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [jsxToRender, setJsxToRender] = useState<JSX.Element | null>(null);
  const [query, setQuery] = useState<ParsedUrlQuery>({});

  const renderContentForScreenshots = (options: RenderContentOptions) => {
    const { jsx, elementRef, width = "100%", height = "100%", query = {} } = options;

    setQuery(query);

    if (!containerRef.current) {
      const container = document.createElement("div");

      container.className = "ScreenshotContainer invisible-container";
      Object.assign(container.style, { width, height });
      document.body.appendChild(container);
      containerRef.current = container;
    }

    if (elementRef?.current) {
      const clonedElement = elementRef.current.cloneNode(true) as HTMLElement;

      containerRef.current.appendChild(clonedElement);
    }

    if (jsx) {
      flushSync(() => setJsxToRender(jsx));
    }
  };

  const takeScreenshots = async (querySelectors: string[]): Promise<{ [key: string]: string }> => {
    if (!containerRef.current) {
      throw new Error("Container not available for screenshots");
    }

    const screenshots: { [key: string]: string } = {};

    await Promise.all(
      querySelectors.map(async (selector) => {
        const element = containerRef.current!.querySelector(selector);

        if (element) {
          const imageBlob = await domtoimage.toPng(element);

          screenshots[selector] = imageBlob;
        }
      }),
    );

    setJsxToRender(null);

    if (containerRef.current) {
      document.body.removeChild(containerRef.current);
      containerRef.current = null;
    }

    return screenshots;
  };

  return (
    <ScreenshotContext.Provider value={{ renderContentForScreenshots, takeScreenshots, query }}>
      {children}
      {jsxToRender && containerRef.current && createPortal(jsxToRender, containerRef.current)}
    </ScreenshotContext.Provider>
  );
};

const withScreenshotContextProvider = (Component: (props: any) => JSX.Element) => {
  return (props: PropsWithChildren) => (
    <ScreenshotContextProvider>
      <Component {...props} />
    </ScreenshotContextProvider>
  );
};

export { ScreenshotContextProvider, withScreenshotContextProvider, ScreenshotContext };
export default useScreenshotContext;
