/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Building } from '../models/Building';
import type { BuildingProperties } from '../models/BuildingProperties';
import type { DomesticHotWaterSystem } from '../models/DomesticHotWaterSystem';
import type { ECMApplicability } from '../models/ECMApplicability';
import type { Envelope } from '../models/Envelope';
import type { HVACComponent } from '../models/HVACComponent';
import type { HVACSystem } from '../models/HVACSystem';
import type { LightingDetail } from '../models/LightingDetail';
import type { PatchedBuilding } from '../models/PatchedBuilding';
import type { PatchedBuildingProperties } from '../models/PatchedBuildingProperties';
import type { PatchedDomesticHotWaterSystem } from '../models/PatchedDomesticHotWaterSystem';
import type { PatchedECMApplicability } from '../models/PatchedECMApplicability';
import type { PatchedEnvelope } from '../models/PatchedEnvelope';
import type { PatchedHVACComponent } from '../models/PatchedHVACComponent';
import type { PatchedHVACSystem } from '../models/PatchedHVACSystem';
import type { PatchedLightingDetail } from '../models/PatchedLightingDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BldgSciService {

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsList(): CancelablePromise<Array<Building>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/',
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsCreate({
        requestBody,
    }: {
        requestBody: Building,
    }): CancelablePromise<Building> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DomesticHotWaterSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsDomesticHotWaterSystemList({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<Array<DomesticHotWaterSystem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/domestic-hot-water-system/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns DomesticHotWaterSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsDomesticHotWaterSystemCreate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody: DomesticHotWaterSystem,
    }): CancelablePromise<DomesticHotWaterSystem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/{building_token}/domestic-hot-water-system/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DomesticHotWaterSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsDomesticHotWaterSystemRetrieve({
        buildingToken,
        instanceId,
    }: {
        buildingToken: string,
        instanceId: string,
    }): CancelablePromise<DomesticHotWaterSystem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/domestic-hot-water-system/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
        });
    }

    /**
     * @returns DomesticHotWaterSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsDomesticHotWaterSystemUpdate({
        buildingToken,
        instanceId,
        requestBody,
    }: {
        buildingToken: string,
        instanceId: string,
        requestBody: DomesticHotWaterSystem,
    }): CancelablePromise<DomesticHotWaterSystem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/bldg_sci/buildings/{building_token}/domestic-hot-water-system/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DomesticHotWaterSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsDomesticHotWaterSystemPartialUpdate({
        buildingToken,
        instanceId,
        requestBody,
    }: {
        buildingToken: string,
        instanceId: string,
        requestBody?: PatchedDomesticHotWaterSystem,
    }): CancelablePromise<DomesticHotWaterSystem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{building_token}/domestic-hot-water-system/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsDomesticHotWaterSystemDestroy({
        buildingToken,
        instanceId,
    }: {
        buildingToken: string,
        instanceId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{building_token}/domestic-hot-water-system/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
        });
    }

    /**
     * @returns ECMApplicability
     * @throws ApiError
     */
    public static bldgSciBuildingsEcmApplicabilitiesList({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<Array<ECMApplicability>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/ecm-applicabilities/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns ECMApplicability
     * @throws ApiError
     */
    public static bldgSciBuildingsEcmApplicabilitiesCreate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody: ECMApplicability,
    }): CancelablePromise<ECMApplicability> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/{building_token}/ecm-applicabilities/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ECMApplicability
     * @throws ApiError
     */
    public static bldgSciBuildingsEcmApplicabilitiesRetrieve({
        buildingToken,
        ecmName,
    }: {
        buildingToken: string,
        ecmName: string,
    }): CancelablePromise<ECMApplicability> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/ecm-applicabilities/{ecm_name}/',
            path: {
                'building_token': buildingToken,
                'ecm_name': ecmName,
            },
        });
    }

    /**
     * @returns ECMApplicability
     * @throws ApiError
     */
    public static bldgSciBuildingsEcmApplicabilitiesUpdate({
        buildingToken,
        ecmName,
        requestBody,
    }: {
        buildingToken: string,
        ecmName: string,
        requestBody: ECMApplicability,
    }): CancelablePromise<ECMApplicability> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/bldg_sci/buildings/{building_token}/ecm-applicabilities/{ecm_name}/',
            path: {
                'building_token': buildingToken,
                'ecm_name': ecmName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ECMApplicability
     * @throws ApiError
     */
    public static bldgSciBuildingsEcmApplicabilitiesPartialUpdate({
        buildingToken,
        ecmName,
        requestBody,
    }: {
        buildingToken: string,
        ecmName: string,
        requestBody?: PatchedECMApplicability,
    }): CancelablePromise<ECMApplicability> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{building_token}/ecm-applicabilities/{ecm_name}/',
            path: {
                'building_token': buildingToken,
                'ecm_name': ecmName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsEcmApplicabilitiesDestroy({
        buildingToken,
        ecmName,
    }: {
        buildingToken: string,
        ecmName: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{building_token}/ecm-applicabilities/{ecm_name}/',
            path: {
                'building_token': buildingToken,
                'ecm_name': ecmName,
            },
        });
    }

    /**
     * @returns Envelope
     * @throws ApiError
     */
    public static bldgSciBuildingsEnvelopeRetrieve({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<Envelope> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/envelope/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns Envelope
     * @throws ApiError
     */
    public static bldgSciBuildingsEnvelopeCreate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody: Envelope,
    }): CancelablePromise<Envelope> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/{building_token}/envelope/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Envelope
     * @throws ApiError
     */
    public static bldgSciBuildingsEnvelopePartialUpdate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody?: PatchedEnvelope,
    }): CancelablePromise<Envelope> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{building_token}/envelope/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsEnvelopeDestroy({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{building_token}/envelope/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns HVACComponent
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacComponentList({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<Array<HVACComponent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/hvac-component/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns HVACComponent
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacComponentCreate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody: HVACComponent,
    }): CancelablePromise<HVACComponent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/{building_token}/hvac-component/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HVACComponent
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacComponentRetrieve({
        buildingToken,
        instanceId,
    }: {
        buildingToken: string,
        instanceId: string,
    }): CancelablePromise<HVACComponent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/hvac-component/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
        });
    }

    /**
     * @returns HVACComponent
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacComponentUpdate({
        buildingToken,
        instanceId,
        requestBody,
    }: {
        buildingToken: string,
        instanceId: string,
        requestBody: HVACComponent,
    }): CancelablePromise<HVACComponent> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/bldg_sci/buildings/{building_token}/hvac-component/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HVACComponent
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacComponentPartialUpdate({
        buildingToken,
        instanceId,
        requestBody,
    }: {
        buildingToken: string,
        instanceId: string,
        requestBody?: PatchedHVACComponent,
    }): CancelablePromise<HVACComponent> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{building_token}/hvac-component/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacComponentDestroy({
        buildingToken,
        instanceId,
    }: {
        buildingToken: string,
        instanceId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{building_token}/hvac-component/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
        });
    }

    /**
     * @returns HVACSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacSystemList({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<Array<HVACSystem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/hvac-system/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns HVACSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacSystemCreate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody: HVACSystem,
    }): CancelablePromise<HVACSystem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/{building_token}/hvac-system/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HVACSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacSystemRetrieve({
        buildingToken,
        instanceId,
    }: {
        buildingToken: string,
        instanceId: string,
    }): CancelablePromise<HVACSystem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/hvac-system/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
        });
    }

    /**
     * @returns HVACSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacSystemUpdate({
        buildingToken,
        instanceId,
        requestBody,
    }: {
        buildingToken: string,
        instanceId: string,
        requestBody: HVACSystem,
    }): CancelablePromise<HVACSystem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/bldg_sci/buildings/{building_token}/hvac-system/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HVACSystem
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacSystemPartialUpdate({
        buildingToken,
        instanceId,
        requestBody,
    }: {
        buildingToken: string,
        instanceId: string,
        requestBody?: PatchedHVACSystem,
    }): CancelablePromise<HVACSystem> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{building_token}/hvac-system/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsHvacSystemDestroy({
        buildingToken,
        instanceId,
    }: {
        buildingToken: string,
        instanceId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{building_token}/hvac-system/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
        });
    }

    /**
     * @returns LightingDetail
     * @throws ApiError
     */
    public static bldgSciBuildingsLightingDetailsList({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<Array<LightingDetail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/lighting-details/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns LightingDetail
     * @throws ApiError
     */
    public static bldgSciBuildingsLightingDetailsCreate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody: LightingDetail,
    }): CancelablePromise<LightingDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/{building_token}/lighting-details/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LightingDetail
     * @throws ApiError
     */
    public static bldgSciBuildingsLightingDetailsRetrieve({
        buildingToken,
        instanceId,
    }: {
        buildingToken: string,
        instanceId: string,
    }): CancelablePromise<LightingDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/lighting-details/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
        });
    }

    /**
     * @returns LightingDetail
     * @throws ApiError
     */
    public static bldgSciBuildingsLightingDetailsUpdate({
        buildingToken,
        instanceId,
        requestBody,
    }: {
        buildingToken: string,
        instanceId: string,
        requestBody: LightingDetail,
    }): CancelablePromise<LightingDetail> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/bldg_sci/buildings/{building_token}/lighting-details/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns LightingDetail
     * @throws ApiError
     */
    public static bldgSciBuildingsLightingDetailsPartialUpdate({
        buildingToken,
        instanceId,
        requestBody,
    }: {
        buildingToken: string,
        instanceId: string,
        requestBody?: PatchedLightingDetail,
    }): CancelablePromise<LightingDetail> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{building_token}/lighting-details/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsLightingDetailsDestroy({
        buildingToken,
        instanceId,
    }: {
        buildingToken: string,
        instanceId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{building_token}/lighting-details/{instance_id}/',
            path: {
                'building_token': buildingToken,
                'instance_id': instanceId,
            },
        });
    }

    /**
     * @returns BuildingProperties
     * @throws ApiError
     */
    public static bldgSciBuildingsPropertiesRetrieve({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<BuildingProperties> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{building_token}/properties/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * @returns BuildingProperties
     * @throws ApiError
     */
    public static bldgSciBuildingsPropertiesCreate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody: BuildingProperties,
    }): CancelablePromise<BuildingProperties> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/bldg_sci/buildings/{building_token}/properties/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BuildingProperties
     * @throws ApiError
     */
    public static bldgSciBuildingsPropertiesPartialUpdate({
        buildingToken,
        requestBody,
    }: {
        buildingToken: string,
        requestBody?: PatchedBuildingProperties,
    }): CancelablePromise<BuildingProperties> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{building_token}/properties/',
            path: {
                'building_token': buildingToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsPropertiesDestroy({
        buildingToken,
    }: {
        buildingToken: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{building_token}/properties/',
            path: {
                'building_token': buildingToken,
            },
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsRetrieve({
        token,
    }: {
        token: string,
    }): CancelablePromise<Building> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/buildings/{token}/',
            path: {
                'token': token,
            },
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsUpdate({
        token,
        requestBody,
    }: {
        token: string,
        requestBody: Building,
    }): CancelablePromise<Building> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/bldg_sci/buildings/{token}/',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns Building
     * @throws ApiError
     */
    public static bldgSciBuildingsPartialUpdate({
        token,
        requestBody,
    }: {
        token: string,
        requestBody?: PatchedBuilding,
    }): CancelablePromise<Building> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/bldg_sci/buildings/{token}/',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Note: this is a core fact table. No update is allowed (delete only).
     * @returns void
     * @throws ApiError
     */
    public static bldgSciBuildingsDestroy({
        token,
    }: {
        token: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bldg_sci/buildings/{token}/',
            path: {
                'token': token,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static bldgSciModelSchemasRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bldg_sci/model-schemas/',
        });
    }

}
